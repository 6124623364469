import React from 'react';
import './Home.css';

const Home: React.FC<{ onNavItemClicked: (sectionName: string) => void }> = ({ onNavItemClicked }) => {
    const learnMoreClicked = () => {
        const url = "https://gradassist.actualaiz.com/";
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="home-section">
            <video className="background-video" autoPlay loop muted playsInline>
                <source src="background-1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay-gradient"></div>
            <div className="content-container">
                <div className="hero-text">
                    <h1 className="main-heading">
                        Empower Your Future <br />
                        <span className="highlight">With Expert-Driven AI</span>
                    </h1>
                    <p className="subheading">
                        Tailored AI and Data Solutions to Drive Sustainable Growth and Future-Ready Success
                    </p>
                </div>
            </div>

            {/* "What's New" Section */}
            <div className="whats-new-section">
                <p className="whats-new-title">What's New</p>
                <p className="whats-new-description">
                    Introducing GradAssist: Your Companion in the Graduate Journey
                </p>
                <button className="visit-gradassist-button" onClick={learnMoreClicked}>
                    Visit GradAssist
                </button>
            </div>
        </div>
    );
};
export default Home;
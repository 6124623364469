import React, { useEffect, useRef } from 'react';
import './Carosel.css';

const Carousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        const { current: carousel } = carouselRef;
        if (carousel.scrollLeft >= (carousel.scrollWidth - carousel.clientWidth) / 2) {
          carousel.scrollLeft = 0;
        } else {
          carousel.scrollLeft += 1;
        }
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  const items: string[] = [
    "PhDs and Postdocs from institutions like CMU and UC Berkeley with decades of recognized work in AI, ML, and computer science",
    "Publications in top-tier conferences and journals, including ACM SIGCOMM, ACL, EMNLP, Harvard Misinformation Review, CHI Interspeech, JDE, and WebConf.",
    "Prestigious grant awards from Google, Meta, National Institute of Health, UNICEF Innovation Fund, National Academy of Sciences, Bill & Melinda Gates Foundation, and more",
    "Honors including but not limited to Google Faculty Research Award, Meta Research Award, AI 2000’s most influential scholars",
  ];

  return (
    <div className="carousel" ref={carouselRef}>
      <div className="carousel-inner">
        {items.map((item, index) => (
          <div key={index} className="carousel-item">
            {item}
          </div>
        ))}
        {items.map((item, index) => (
          <div key={`duplicate-${index}`} className="carousel-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './About.css';

const About: React.FC<{ onNavItemClicked: (sectionName: string) => void }> = ({ onNavItemClicked }) => {
    const navigate = useNavigate();
    const Ipsum = "Empowering businesses with tailored Generative AI solutions, our services deliver seamless integrations, personalized recommendations, and transformative insights for accelerated growth and innovation in both B2B and B2C markets.";

    const [animatedSpans, setAnimatedSpans] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const spans = [];
        const temp = Ipsum.split(" ");
        for (let i = 0; i < temp.length; i++) {
            const span = (
                <span style={{ animation: `scale 500ms ${i * 100}ms ease forwards` }} key={i}>
                    {temp[i] + " "}
                </span>
            );
            spans.push(span);
        }
        setAnimatedSpans(spans);
    }, []);

    const handleMoreAboutUsClick = () => {
        navigate('/about-us');
        window.scrollTo(0, 0);
    };

    const handlePrivacyClick = () => {
        navigate('/privacy');
        window.scrollTo(0, 0);
    };
    const handleFAQClick = () => {
        navigate('/faqs');
        window.scrollTo(0, 0);
    };

    const handleProductsClick = () => {
        navigate('/products-and-services');
        window.scrollTo(0, 0);
    };

    return (
        <div className='about-container'>
            <div className='section'>
                <p id="quote">{animatedSpans}</p>
                <div className='more-about-us' onClick={handleMoreAboutUsClick}>
                    <div>About Us</div>
                </div>
            </div >
            <div className='h-line'></div>
            <div className='contact-us'>
                <div className='contact-info'>
                    <div>© ActualAIz, Inc.</div>
                    <div className='social-media-icons'>
                        <a href="https://www.instagram.com/actualaiz" target="_blank" rel="noopener noreferrer"><img className='insta' src="Instagram.png" alt="Instagram" /></a>
                        <a href="https://www.facebook.com/profile.php?id=61559163372895&mibextid=qi2Omg&rdid=XS2npHqPisaR5XwV" target="_blank" rel="noopener noreferrer"><img className='fb' src="Facebook.png" alt="Facebook" /></a>
                        <a href="https://www.linkedin.com/company/actualaiz/" target="_blank" rel="noopener noreferrer"><img className='ln' src="LinkedIn.png" alt="LinkedIn" /></a>
                        <a href="https://www.youtube.com/@Actualaiz" target="_blank" rel="noopener noreferrer"><img className='yt' src="Youtube.png" alt="YouTube" /></a>
                        <a href="https://twitter.com/actualaiz?t=t4psN-zCgQa-9EiuGqRazw&s=08" target="_blank" rel="noopener noreferrer"><img className='x' src="X.png" alt="X" /></a>
                    </div>
                </div>
                {/* Right-aligned links */}
                <div className='right-links'>
                    <div className='p-policy' onClick={handlePrivacyClick}>Privacy Policy</div>
                    <div className='p-policy' onClick={handleFAQClick}>FAQs</div>
                    {/* Terms of Service Link */}
                    <div className='p-policy'>
                        <a href="/TermsOfService.pdf" target="_blank" rel="noopener noreferrer">
                            Terms of Service
                        </a>
                    </div>
                    {/* Products and Services navigation */}
                    <div className='p-policy' onClick={handleProductsClick}>
                        Products and Services
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;

import React, { useState } from 'react';
import './Team.css';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';

interface TeamMember {
    id: number;
    name: string;
    img: string;
    info: string;
}

const teamMembers: TeamMember[] = [
    { id: 1, name: 'Dr. Zafar Ayyub Qazi', img: 'zafarqazi.png', info: 'Dr. Zafar Ayyub Qazi is a co-founder of Actualaiz and a distinguished computer scientist. He has been recognized with multiple awards, including the Google Faculty Research Award, and was named one of the top 100 most influential computer scientists in networked systems by the AI 2000 list. He also serves as an Assistant Professor of Computer Science at Lahore University of Management Sciences (LUMS), a position he has held since 2018. Prior to LUMS, he was a postdoctoral scholar at UC Berkeley\'s EECS Department from 2016 to 2017. Dr. Qazi earned his PhD from the State University of New York at Stony Brook. He has extensive experience in designing and building large-scale distributed systems. His current research explores the applications of Large Language Models (LLMs), design of large-scale distributed systems, mobile computing, and improving Internet affordability.' },
    { id: 2, name: 'Dr. Agha Ali Raza', img: 'aghaali.jpg', info: 'Dr. Agha Ali Raza, a co-founder of ActualAIz and Assistant Professor at LUMS, is a globally recognized leader in Machine Learning, Speech and Natural Language Processing, and technologies for Emerging Markets. A Fulbright scholar with a Ph.D. from Carnegie Mellon University, USA, Dr. Raza has spent over 19 years pioneering accessible and inclusive digital solutions. His innovative projects, including the viral speech-based platform Polly, the maternal health hotline Super Abbu, the IVR social network Baang, the quora-like service for low-literates Sawaal, and the voice-based crowd-work platform Karamad, have empowered over 300,000 low-literate and non-tech-savvy individuals across Pakistan, India, and West Africa. These platforms provide crucial information on health, immunization, jobs, COVID-19, Ebola, and more, truly transforming lives. Dr. Raza\'s work has attracted significant funding from prestigious organizations including UNICEF, GIZ, Facebook/Meta, the Gates Foundation, NIH, NAKFI, Google, and HEC, totaling over USD 1.6 million. His vision is to create a safe, equitable digital world where every individual has equitable opportunities to thrive.    ' },
    { id: 3, name: 'Dr. Ihsan Ayyub Qazi', img: 'ihsanqazi.png', info: 'Dr. Ihsan Ayyub Qazi is a cofounder of Actualaiz. He is celebrated for his outstanding contributions to computer science and is a recipient of several prestigious awards including the Google Faculty Research Award, three Meta/Facebook Integrity Foundational Research Awards, ACM SIGCOMM CCR Best Paper Award, Google exploreCSR Award, and the Sheth International Young Alumni Achievement Award 2021 from the University of Pittsburgh, USA. He also serves as a Tenured Associate Professor of Computer Science at Lahore University of Management Sciences (LUMS), where he held the position of Department Chair from 2018 to 2021. As a Principal Investigator at the National Center in Big Data & Cloud Computing (NCBC), he embodies the spirit of innovation that is crucial for entrepreneurs. His research interests are in networked systems and their societal impacts. His current research centers on Large Language Models (LLMs), cloud/edge computing, digital development, countering misinformation and privacy-preserving machine learning. He was a visiting Research Scientist at the University of California at Berkeley in 2017 and at RWTH Aachen University, Germany in 2016, worked as a Postdoctoral Research Fellow in Australia from 2010-11, and received his Ph.D. in Computer Science from the University of Pittsburgh, USA in 2010.' },
    { id: 4, name: 'Dr. Mukhtiar Ahmad', img: 'mukhtiarahmed.jpeg', info: 'Dr. Mukhtiar Ahmad holds the position of Team Lead at ActualAIz Pvt. Ltd., leveraging over 14 years of extensive industry experience in safety-critical embedded systems and systems design. He obtained his Ph.D. from LUMS in 2023, where he published his research at prestigious conferences such as ACM SIGCOMM, ACM CoNEXT, and IEEE Transactions on Networking, focusing primarily on supporting cellular networks for latency-sensitive edge applications. Dr. Mukhtiar\'s recent endeavors in exploring the applications of Large Language Models (LLMs) and RAG exemplify his steadfast commitment to innovation, positioning him at the forefront of technological advancement.' },
    { id: 5, name: 'Muhammad Tahir', img: 'tahirmunir.jpeg', info: 'Muhammad Tahir is a valuable team member of Actualize and a Senior Research Associate at Lahore University of Management Sciences (LUMS). With over six years of experience in machine learning, Muhammad specializes in Federated Learning and privacy-preserving machine learning. Muhammad\'s current research initiatives are centered on domain-specific Large Language Models (LLMs). His scholarly contributions are well-recognized, with publications presented at top-tier conferences such as ECML and The Web Conference (WWW). At Actualize, Muhammad leverages his deep expertise to innovate and train domain-specific LLMs, developing tailored and efficient machine learning solutions. His role at Actualize and LUMS demonstrates his commitment to advancing the boundaries of machine learning research and its practical applications.    ' },
    { id: 7, name: 'Mutahar Ali', img: 'mutahar.png', info: 'Mutahar Ali is a Research Associate at ActualAIz. He earned his Bachelor\'s in Computer Science from Lahore University of Management Sciences (LUMS), graduating in 2023. Mutahar has a diverse research background with experience in distributed systems, computer networks, and applied machine learning research. At ActualAIz, Mutahar works on development of scalable software for LLM-powered applications, In-Context Learning through Retrieval Augmented Generation (RAG), and software deployment.' },
    { id: 8, name: 'Samee Arif', img: 'samee.jpeg', info: 'Samee Arif is a Research Associate at Actualaiz. He completed his Bachelor of Science in Computer Science in 2023 from LUMS. He worked as a Research Assistant at the Center for Speech and Language Technologies from August 2021 to May 2023. His research focuses on Information & Communication Technologies for Development, Human-Computer Interaction, and Speech and Natural Language Processing. He is committed to leveraging these fields to create meaningful social impacts, particularly by enhancing accessibility and usability of technology. To improve inclusivity and accessibility of Urdu in natural language processing, he recently published a research paper at LREC-COLING 2024 – Joint International Conference on Computational Linguistics, Language Resources and Evaluation.' },
    { id: 9, name: 'Hamid Nawaz', img: 'hamid.png', info: 'Hamid is a Management Science (Operations Research) graduate from LUMS Class of 2023. He currently serves as the Product and Business Development Manager at Actualaiz. Hamid is a trained data scientist and analytics expert with 2 years of demonstrated experience with renowned Fin Tech startups, International Consultancie, and the Federal Ministry of Planning and Developmen. He has also served as a research associate at LUMS, with his work primarily focusing on leveraging data science and machine learning to aid development policy. At Actualaiz, Hamid utilizes his joint expertise in data based products and business understanding to optimize our business and product strategy.' },
    { id: 10, name: 'Wassay Sajjad', img: 'wassay.png', info: 'Description of the new team member.' },
    { id: 11, name: 'Hamza Khawaja', img: 'hamza_khawaja.png', info: 'Description of the new team member.' },
    { id: 12, name: 'Shazer Ali', img: 'shazer.png', info: 'Description of the new team member.' },

];

const Team: React.FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

    const openModal = (member: TeamMember) => {
        setSelectedMember(member);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const nextMember = () => {
        const currentIndex = teamMembers.findIndex(m => m.id === selectedMember?.id);
        const nextIndex = (currentIndex + 1) % teamMembers.length;
        setSelectedMember(teamMembers[nextIndex]);
    };

    const previousMember = () => {
        const currentIndex = teamMembers.findIndex(m => m.id === selectedMember?.id);
        const prevIndex = (currentIndex - 1 + teamMembers.length) % teamMembers.length;
        setSelectedMember(teamMembers[prevIndex]);
    };

    return (
        <div className='team-section'>
            <h2>Our Vision<div className='vision-statement'><i>Empowering Progress: Expert-Driven Generative AI Solutions for Tomorrow</i></div></h2>
        <p className='vision'>
            At ActualAIz, we recognize the unique challenges faced by businesses and individuals in a rapidly evolving technological landscape. Whether it's navigating complex business decisions, implementing advanced AI-driven workflows, or exploring the possibilities of Generative AI, we provide the tools and guidance to turn aspirations into measurable results.<br></br><br></br>

            Despite the immense potential of Generative AI, many organizations struggle to access the expertise and solutions required to thrive in a competitive world. Questions such as “How can AI optimize my business processes?”, “What are the risks of adopting AI in my industry?”, or “How do I make AI solutions scalable and transparent?” often go unanswered due to the lack of tailored, impactful, and affordable solutions.<br></br><br></br>

            This gap between potential and implementation informs the vision of ActualAIz. Our mission is to deliver cutting-edge, expert-driven, and AI-enhanced solutions that empower businesses and individuals to innovate, scale, and lead with confidence.<br></br><br></br>

            We believe that an effective AI partner offers more than just technology. At ActualAIz, we provide solutions that are personalized, transparent, and scalable, ensuring each organization receives relevant and actionable insights. Our deep understanding of diverse industries enables us to offer globally informed perspectives while remaining grounded in the unique needs of each client.<br></br><br></br>

            Our commitment to excellence is guided by three core principles:<br></br><br></br>
            <ol className='list-vision'>
                <li className='item'>We aim to empower organizations with AI solutions that are accessible, scalable, and seamlessly integrated into their workflows.</li>
                <li className='item'>We drive innovation by creating tools that solve real-world challenges, fostering growth and efficiency for our clients.</li>
                <li className='item'>We are committed to building inclusivity, ensuring businesses of all sizes can harness the power of Generative AI.</li>
            </ol>
            At ActualAIz, we don’t just provide AI solutions — we create partnerships to shape a future where technology meets purpose and innovation drives success.
        </p>
            <h2>Co-Founders</h2>
            <div className='team-leads'>
                {teamMembers.slice(0, 3).map(member => (
                    <div key={member.id} className='team-member' onClick={() => openModal(member)}>
                        <img src={member.img} alt={member.name} />
                        <p>{member.name}</p>
                    </div>
                ))}
            </div>

            <h2>Team</h2>
            <div className='team-members'>
                {teamMembers.slice(3).map(member => (
                    <div key={member.id} className='team-member' onClick={() => openModal(member)}>
                        <img src={member.img} alt={member.name} />
                        <p>{member.name}</p>
                    </div>
                ))}
            </div>

            {modalOpen && selectedMember && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='close' onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <img className='modal-image' src={selectedMember.img} alt={selectedMember.name} />
                        <div className='test'>
                            <h3 className='name'>{selectedMember.name}</h3>
                            <p className='info'>{selectedMember.info}</p>
                            <div className='navi'>
                                <div className='prev' onClick={previousMember}>
                                    <NavigateBeforeIcon />
                                </div>
                                <div className='next' onClick={nextMember}>
                                    <NavigateNextIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Team;

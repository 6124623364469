import React, { useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Team from './pages/Team/Team';
import Privacy from './pages/Privacy/Privacy';
import FAQ from './pages/FAQs/Faqs';
import ProductsAndServices from './pages/ProductsAndServices/ProductsAndServices';
import Products from './pages/Products/Products';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Carosel from './components/Carosel/Carosel';

function App() {
  const appRef = useRef<HTMLDivElement>(null);

  const onNavItemClicked = (sectionName: string) => {
    scroller.scrollTo(sectionName, {
      duration: 1400,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <Router>
      <div className="App" ref={appRef}>
        <Navbar onNavItemClicked={onNavItemClicked} />
        <Routes>
          <Route
            path="/"
            element={
              <div className="content-wrapper">
                {/* Wrapping Carosel and Home together */}
                <div className="home-and-carosel-wrapper">
                  <Element name="homeSection">
                    <Home onNavItemClicked={onNavItemClicked} />
                  </Element>
                  <div className="carosel">
                    <Carosel />
                  </div>
                </div>
                <Element name="productsSection">
                  <Products />
                </Element>
                <Element name="teamSection">
                  <About onNavItemClicked={onNavItemClicked} />
                </Element>
              </div>
            }
          />
          <Route path="/about-us" element={<Team />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products-and-services" element={<ProductsAndServices />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

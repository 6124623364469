import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import './Products.css';

const Products: React.FC = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    // Define the type for dataset categories
    type CategoryKey = 'Audio' | 'Text';

    const [showDataset, setShowDataset] = useState(false); // State for showing dataset modal
    const [activeCategory, setActiveCategory] = useState<CategoryKey>('Audio'); // Default active category

    const productInfo = [
        {
            src: 'customGenAI_img.jpg',
            text: 'Our Generative AI solutions are built on cutting-edge multi-agent systems with a strong emphasis on robust evaluation. We deliver scalable, fair, and transparent tools that integrate seamlessly into your workflows, driving measurable impact.',
            title: "Tailored Generative AI Solutions",
        },
        {
            src: 'edtech.jpg',
            text: 'We deliver transformative, AI-driven EdTech solutions tailored for both B2B and B2C clients. From intelligent learning platforms to automated content generation and analytics, our services redefine education technology, enabling personalized, efficient, and engaging learning experiences.',
            title: "Personalized AI-Driven Learning Tools",
        },
        {
            src: 'dataset.jpg',
            text: 'We offer end-to-end data excellence. We not only process and structure existing content, but also generate custom datasets from scratch. Whether its speech, image, or video data, we deliver high-quality assets through meticulous collection, labeling, engineering, and dataset creation.',
            title: "Your Gateway to AI-Ready Data",
        },
    ];

    const datasetCategories: Record<
        CategoryKey,
        {
            Feature?: string;
            audioPath?: string;
            urduTranscription?: string;
            urduTranslation?: string;
        }[]
    > = {
        Audio: [
            {
                audioPath: '/audio/1.wav',
                urduTranscription: 'اب یہ چیزیں شاید ہی آپ کو دیکھنے کو ملتی ہوں اور اگر کہیں مل بھی جائیں تو کسی کونے میں غیرضروری شے کے طور پر',
            },
            {
                audioPath: '/audio/2.wav',
                urduTranscription: 'لک میں پانی کی کمی کی وجہ سے بیشتر کسان اپنی زمینیں چھوڑنے پر مجبور ہیں اور نئے کام کی تلاش میں شہروں کا رخ کر رہے ہیں۔',
            },
        ],
        
        Text: [
            {
                Feature: 'Text: "This is a positive example."',
                urduTranslation: 'یہ ایک مثبت مثال ہے۔',
            },
            {
                Feature: 'Text: "This is a negative example."',
                urduTranslation: 'یہ ایک منفی مثال ہے۔',
            },
        ],
    };

    // Function to handle the button click for navigation
    const handleGetInTouch = () => {
        navigate('/contact'); // Redirect to the Contact Us page
    };

    return (
        <div className='products-section'>
            <h2 className='services-heading'>SERVICES</h2>
            <div className='product-container'>
                {productInfo.map((product, index) => (
                    <React.Fragment key={index}>
                        <div className='product-item'>
                            <div className='product-title'>{product.title}</div>
                            <img src={product.src} alt={product.text} className='product-image' />
                            <div className='product-text'>{product.text}</div>
                            {/* {product.title === "Your Gateway to AI-Ready Data" && (
                                <button
                                    className="view-dataset-button"
                                    onClick={() => setShowDataset(true)}
                                >
                                    View Sample Dataset
                                </button>
                            )} */}
                        </div>
                        {index < productInfo.length - 1 && <div className='vertical-line'></div>}
                    </React.Fragment>
                ))}
            </div>

            {/* Button to redirect to Contact Us */}
            <button className='get-in-touch-button' onClick={handleGetInTouch}>
                Get In Touch
            </button>

            {/* Modal for Dataset Snippet */}
            {showDataset && (
                <div className="dataset-modal">
                    <div className="dataset-content">
                        {/* Dropdown to Select Category */}
                        <div className="category-dropdown">
                            {Object.keys(datasetCategories).map((category) => (
                                <button
                                    key={category}
                                    className={`category-button ${
                                        activeCategory === category ? 'active' : ''
                                    }`}
                                    onClick={() => setActiveCategory(category as CategoryKey)}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>

                        {/* Display Data for Selected Category */}
                        {activeCategory === 'Audio' ? (
                            <div className="audio-data-section">
                                {datasetCategories.Audio.map((item, index) => (
                                    <div key={index} className="audio-item">
                                        <audio controls className="audio-player">
                                            <source src={item.audioPath} type="audio/wav" />
                                            Your browser does not support the audio element.
                                        </audio>
                                        <div className="audio-transcription">
                                            <p>
                                                <strong>اردو:</strong> {item.urduTranscription}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <table className="dataset-table">
                                <thead>
                                    <tr>
                                        <th>English</th>
                                        <th>Translation (Urdu)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datasetCategories[activeCategory].map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.Feature}</td>
                                            <td>{item.urduTranslation}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        {/* Close Modal Button */}
                        <button
                            className="close-modal-button"
                            onClick={() => setShowDataset(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Products;
